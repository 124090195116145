import { Attributes, Span, SpanStatusCode, trace } from '@opentelemetry/api';

import { measure } from './timing';

export const SERVICE_NAME = 'cottage-shop-web';
export const TRACER_NAME = 'default';

/**
 * Creates a span in the current tracing context.
 *
 * Use {@link withTracing} unless you need direct access to the OpenTelemetry span.
 */
export async function startSpan<T>(spanName: string, fn: (span: Span) => Promise<T>): Promise<T> {
  return await trace.getTracer(TRACER_NAME).startActiveSpan(spanName, async (span) => {
    try {
      return await fn(span);
    } finally {
      span.end();
    }
  });
}

/** Helper function to run an async function with OpenTelemetry tracing. */
export async function withTracing<T>(
  spanName: string,
  spanAttributes: Attributes,
  fn: () => Promise<T>
): Promise<T> {
  return startSpan(spanName, async (span) => {
    span.setAttributes(spanAttributes);

    try {
      const result = await measure(spanName, fn);
      span.setStatus({
        code: SpanStatusCode.OK,
      });
      return result;
    } catch (e) {
      span.setStatus({
        code: SpanStatusCode.ERROR,
        message: e instanceof Error ? e.message : String(e),
      });
      if (e instanceof Error) {
        span.recordException(e);
      }
      throw e;
    }
  });
}
