import { GraphQLResult } from '@aws-amplify/api';
import {
  FilterLocationsInput,
  FilterPlansInput,
  FilterSchedulesInput,
  GetBusinessAboutUsTeamMembersQuery,
  GetBusinessAuthQuery,
  GetBusinessFaqsQuery,
  GetBusinessImagesQuery,
  GetBusinessInput,
  GetBusinessLegalQuery,
  GetBusinessLocationsQuery,
  GetBusinessMetadataInput,
  GetBusinessPlansQuery,
  GetBusinessProductAllergensQuery,
  GetBusinessProductCategoriesQuery,
  GetBusinessPromotedScheduleQuery,
  GetBusinessSettingsQuery,
  PaginationInput,
} from 'API';
import {
  getBusinessAboutFn,
  getBusinessAuthFn,
  getBusinessFaqsFn,
  getBusinessImagesFn,
  getBusinessLegalFn,
  getBusinessLocationsFn,
  getBusinessLocationsPickerFn,
  getBusinessPlansFn,
  getBusinessProductAllergensFn,
  getBusinessProductCategoriesFn,
  getBusinessPromotedScheduleFn,
  getBusinessSettingsFn,
  LocationCard,
} from 'api/query-functions/business';
import { queryWithAmplify } from 'auth';
import { QueryKeys } from 'models/react-query';
import { useQuery, UseQueryOptions } from 'react-query';

export const useGetBusinessAuth = (
  input: GetBusinessInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessAuthQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessAuthQuery>>({
    queryKey: [QueryKeys.BUSINESS_AUTH, input],
    queryFn: queryWithAmplify(getBusinessAuthFn),
    ...options,
  });
};

export const useGetBusinessSettings = (
  input: GetBusinessInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessSettingsQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessSettingsQuery>>({
    queryKey: [QueryKeys.BUSINESS_SETTINGS, input],
    queryFn: queryWithAmplify(getBusinessSettingsFn),
    ...options,
  });
};

export const useGetBusinessImages = (
  input: GetBusinessMetadataInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessImagesQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessImagesQuery>>({
    queryKey: [QueryKeys.BUSINESS_IMAGES, input],
    queryFn: queryWithAmplify(getBusinessImagesFn),
    ...options,
  });
};

export const useGetBusinessAbout = (
  input: GetBusinessMetadataInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessAboutUsTeamMembersQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessAboutUsTeamMembersQuery>>({
    queryKey: [QueryKeys.BUSINESS_ABOUT, input],
    queryFn: queryWithAmplify(getBusinessAboutFn),
    ...options,
  });
};

export const useGetBusinessFaqs = (
  input: GetBusinessMetadataInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessFaqsQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessFaqsQuery>>({
    queryKey: [QueryKeys.BUSINESS_FAQS, input],
    queryFn: queryWithAmplify(getBusinessFaqsFn),
    ...options,
  });
};

export const useGetBusinessLocations = (
  input: GetBusinessInput,
  filters: FilterLocationsInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessLocationsQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessLocationsQuery>>({
    queryKey: [QueryKeys.BUSINESS_LOCATIONS, input, filters],
    queryFn: queryWithAmplify(getBusinessLocationsFn),
    ...options,
  });
};

export const useGetBusinessLocationsPicker = (
  input: GetBusinessInput,
  locationFilters: FilterLocationsInput,
  scheduleFilters: FilterSchedulesInput,
  options: UseQueryOptions<LocationCard[]> = {}
) => {
  return useQuery<LocationCard[]>({
    queryKey: [QueryKeys.BUSINESS_LOCATIONS_PICKER, input, locationFilters, scheduleFilters],
    queryFn: queryWithAmplify(getBusinessLocationsPickerFn),
    ...options,
  });
};

export const useGetBusinessProductAllergens = (
  input: GetBusinessMetadataInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessProductAllergensQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessProductAllergensQuery>>({
    queryKey: [QueryKeys.BUSINESS_PRODUCT_ALLERGENS, input],
    queryFn: queryWithAmplify(getBusinessProductAllergensFn),
    ...options,
  });
};

export const useGetBusinessProductCategories = (
  input: GetBusinessInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessProductCategoriesQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessProductCategoriesQuery>>({
    queryKey: [QueryKeys.BUSINESS_PRODUCT_CATEGORIES, input],
    queryFn: queryWithAmplify(getBusinessProductCategoriesFn),
    ...options,
  });
};

export const useGetBusinessPlans = (
  input: GetBusinessInput,
  filters: FilterPlansInput,
  pagination: PaginationInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessPlansQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessPlansQuery>>({
    queryKey: [QueryKeys.BUSINESS_PLANS, input, filters, pagination],
    queryFn: queryWithAmplify(getBusinessPlansFn),
    ...options,
  });
};

export const useGetBusinessPromotedSchedule = (
  input: GetBusinessMetadataInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessPromotedScheduleQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessPromotedScheduleQuery>>({
    queryKey: [QueryKeys.BUSINESS_PROMOTED_SCHEDULE, input],
    queryFn: queryWithAmplify(getBusinessPromotedScheduleFn),
    ...options,
  });
};

export const useGetBusinessLegal = (
  input: GetBusinessInput,
  options: UseQueryOptions<GraphQLResult<GetBusinessLegalQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetBusinessLegalQuery>>({
    queryKey: [QueryKeys.BUSINESS_LEGAL, input],
    queryFn: queryWithAmplify(getBusinessLegalFn),
    ...options,
  });
};
