import {
  Address,
  GetBusinessAboutUsTeamMembersQuery,
  GetBusinessAuthQuery,
  GetBusinessFaqsQuery,
  GetBusinessImagesQuery,
  GetBusinessInput,
  GetBusinessLegalQuery,
  GetBusinessLegalQueryVariables,
  GetBusinessLocationsPickerQuery,
  GetBusinessLocationsPickerQueryVariables,
  GetBusinessLocationsQuery,
  GetBusinessMetadataInput,
  GetBusinessMetadataQuery,
  GetBusinessPlansQuery,
  GetBusinessPlansQueryVariables,
  GetBusinessProductAllergensQuery,
  GetBusinessProductCategoriesQuery,
  GetBusinessPromotedScheduleQuery,
  GetBusinessSettingsQuery,
  Schedule,
  TransportationType,
} from 'API';
import {
  getBusinessAboutQueryGql,
  getBusinessAuthQueryGql,
  getBusinessFaqsQueryGql,
  getBusinessImagesQueryGql,
  getBusinessLegalQueryGql,
  getBusinessLocationsPickerQueryGql,
  getBusinessLocationsQueryGql,
  getBusinessMetadataQueryGql,
  getBusinessPlansQueryGql,
  getBusinessProductAllergensQueryGql,
  getBusinessProductCategoriesGql,
  getBusinessPromotedScheduleQueryGql,
  getBusinessSettingsQueryGql,
} from 'api/graphql/business';
import { APIClass } from 'aws-amplify';
import { DateTime } from 'luxon';
import { QueryFnProps } from 'models/react-query';

import { executeQuery } from '../helpers/executeQuery';

export type DateTile = {
  dateTimeEpoch: string;
  day: string;
  schedules: Schedule[];
};

export type LocationCard = {
  id: string; // locationId or pickupAddressId
  type: TransportationType;
  title: string; // location title or pickupAddress title fallback to pickupAddress street
  pathSegment: string; // location pathSegment
  availability: DateTile[];
  deliveryRulePostalCodes?: string[];
  pickupAddress?: Address;
  distanceFromPoint?: number;
};

export const getBusinessAuthFn = async (
  { queryKey }: QueryFnProps<GetBusinessInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessAuthQuery>(api, getBusinessAuthQueryGql, { input });
};

export const getBusinessSettingsFn = async (
  { queryKey }: QueryFnProps<GetBusinessInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessSettingsQuery>(api, getBusinessSettingsQueryGql, { input });
};

export const getBusinessImagesFn = async (
  { queryKey }: QueryFnProps<GetBusinessImagesQuery>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessImagesQuery>(api, getBusinessImagesQueryGql, { input });
};

export const getBusinessMetadataFn = async (
  { queryKey }: QueryFnProps<GetBusinessMetadataInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessMetadataQuery>(api, getBusinessMetadataQueryGql, { input });
};

export const getBusinessPromotedScheduleFn = async (
  { queryKey }: QueryFnProps<GetBusinessMetadataInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessPromotedScheduleQuery>(
    api,
    getBusinessPromotedScheduleQueryGql,
    {
      input,
    }
  );
};

export const getBusinessAboutFn = async (
  { queryKey }: QueryFnProps<GetBusinessMetadataInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessAboutUsTeamMembersQuery>(api, getBusinessAboutQueryGql, {
    input,
  });
};

export const getBusinessFaqsFn = async (
  { queryKey }: QueryFnProps<GetBusinessMetadataInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessFaqsQuery>(api, getBusinessFaqsQueryGql, { input });
};

export const getBusinessLocationsFn = async (
  { queryKey }: QueryFnProps<GetBusinessInput>,
  api: APIClass
) => {
  const [, input, filterInput] = queryKey;
  return await executeQuery<GetBusinessLocationsQuery>(api, getBusinessLocationsQueryGql, {
    input,
    filterInput,
  });
};

export const getBusinessLocationsPickerFn = async (
  { queryKey }: QueryFnProps<GetBusinessLocationsPickerQueryVariables>,
  api: APIClass
) => {
  const [, input, filterLocationsInput, filterSchedulesInput] = queryKey;
  const data = await executeQuery<GetBusinessLocationsPickerQuery>(
    api,
    getBusinessLocationsPickerQueryGql,
    {
      input,
      filterLocationsInput,
      filterSchedulesInput,
    }
  );

  // fallback to now just in case
  const today = filterSchedulesInput.dateRange?.start
    ? DateTime.fromMillis(+filterSchedulesInput.dateRange.start)
    : DateTime.now();
  const locations = data?.data?.getBusiness?.locations?.edges?.map((e) => e.node) || [];

  const response: LocationCard[] = [];

  response.push(
    ...locations.map((location) => {
      const deliveryUpcomingSchedules = location.schedules?.edges
        .map((e) => e.node)
        ?.filter((s) => s.type === TransportationType.DELIVERY);

      const deliveryAvailability: Map<string, DateTile> = new Map();

      // init the upcoming week starting today

      for (let i = 0; i <= 7; i++) {
        const dateTime = today.plus({ days: i });
        const dayOfTheWeek = dateTime.toFormat('EEE').toString();
        const dateTile: DateTile = {
          dateTimeEpoch: dateTime.toString(),
          day: dayOfTheWeek,
          schedules: [],
        };
        deliveryAvailability.set(dayOfTheWeek, dateTile);
      }

      deliveryUpcomingSchedules?.forEach((schedule) => {
        const { orderReadyStart } = schedule;
        // find matching day
        const orderReadyStartDay = DateTime.fromMillis(+orderReadyStart).toFormat('EEE').toString();
        const dayDateTile = deliveryAvailability.get(orderReadyStartDay);
        // confirmed this schedule is active on a particular day, flip its flag because we know it has
        // at least one schedule
        if (dayDateTile) {
          deliveryAvailability.set(orderReadyStartDay, {
            ...dayDateTile,
            schedules: [...dayDateTile.schedules, schedule as Schedule],
          });
        }
      });

      // load up the delivery results
      return {
        id: location.id,
        type: TransportationType.DELIVERY,
        title: location.title,
        pathSegment: location.pathSegment,
        availability: Array.from(deliveryAvailability.values()),
        deliveryRulePostalCodes: location.deliveries?.edges.flatMap((e) => e.node.postalCodes),
      } as LocationCard;
    })
  );

  response.push(
    ...locations.flatMap((location) => {
      const pickupUpcomingSchedules = location.schedules?.edges
        .map((e) => e.node)
        .filter((s) => s.type === TransportationType.PICK_UP);

      const pickupCardByPickupAddress: Map<string, Map<string, DateTile>> = new Map();

      // map addressId to address object, needed later
      const addressIdToAddressMap: Map<string, Address> = new Map();

      pickupUpcomingSchedules?.forEach((schedule) => {
        // build availability maps
        const pickupAddress = schedule.pickupAddress;

        // doing this check for typescript, we expect this value to exist on these types of schedules
        if (!pickupAddress) {
          return;
        }
        const key = pickupAddress.id;

        // store address ref
        const address = addressIdToAddressMap.get(key);
        if (!address) {
          addressIdToAddressMap.set(key, pickupAddress);
        }

        // either create a new availability array or append to an existing one
        const availabilityForPickupAddress = pickupCardByPickupAddress.get(key);
        // we've already create a map for this address, add to its availability
        if (!availabilityForPickupAddress) {
          // init the data and add the first entry to the availability

          // init the upcoming week starting today
          const pickupAvailability: Map<string, DateTile> = new Map();
          for (let i = 0; i <= 7; i++) {
            const dateTime = today.plus({ days: i });
            const dayOfTheWeek = dateTime.toFormat('EEE').toString();
            const dateTile: DateTile = {
              dateTimeEpoch: dateTime.toString(),
              day: dayOfTheWeek,
              schedules: [],
            };
            pickupAvailability.set(dayOfTheWeek, dateTile);
          }

          // add the schedule to its correct pickup address
          const { orderReadyStart } = schedule;
          // find matching day
          const orderReadyStartDay = DateTime.fromMillis(+orderReadyStart)
            .toFormat('EEE')
            .toString();
          const dayDateTile = pickupAvailability.get(orderReadyStartDay);

          // confirmed this schedule is active on a particular day, flip its flag because we know it has
          // at least one schedule
          if (dayDateTile) {
            pickupAvailability.set(orderReadyStartDay, {
              ...dayDateTile,
              schedules: [...dayDateTile.schedules, schedule as Schedule],
            });
          }

          // add the entry
          pickupCardByPickupAddress.set(key, pickupAvailability);
        } else {
          const orderReadyStartDay = DateTime.fromMillis(+schedule.orderReadyStart)
            .toFormat('EEE')
            .toString();
          // append to the availability of the existing data
          const dayDateTile = availabilityForPickupAddress.get(orderReadyStartDay);
          if (dayDateTile) {
            availabilityForPickupAddress.set(orderReadyStartDay, {
              ...dayDateTile,
              schedules: [...dayDateTile.schedules, schedule as Schedule],
            });
          }
        }
      });

      return Array.from(pickupCardByPickupAddress).map(([pickupAddressKey, pickupAvailability]) => {
        const address = addressIdToAddressMap.get(pickupAddressKey);
        // load up the delivery results
        return {
          id: pickupAddressKey,
          type: TransportationType.PICK_UP,
          title: address?.title ?? location.title,
          pathSegment: location.pathSegment,
          availability: Array.from(pickupAvailability.values()),
          pickupAddress: address,
        } as LocationCard;
      });
    })
  );

  return response;
};

export const getBusinessProductAllergensFn = async (
  { queryKey }: QueryFnProps<GetBusinessMetadataInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessProductAllergensQuery>(
    api,
    getBusinessProductAllergensQueryGql,
    {
      input,
    }
  );
};

export const getBusinessProductCategoriesFn = async (
  { queryKey }: QueryFnProps<GetBusinessMetadataInput>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessProductCategoriesQuery>(
    api,
    getBusinessProductCategoriesGql,
    {
      input,
    }
  );
};

export const getBusinessPlansFn = async (
  { queryKey }: QueryFnProps<GetBusinessPlansQueryVariables>,
  api: APIClass
) => {
  const [, input, filterInput, pagination] = queryKey;
  return await executeQuery<GetBusinessPlansQuery>(api, getBusinessPlansQueryGql, {
    input,
    filterInput,
    pagination,
  });
};

export const getBusinessLegalFn = async (
  { queryKey }: QueryFnProps<GetBusinessLegalQueryVariables>,
  api: APIClass
) => {
  const [, input] = queryKey;
  return await executeQuery<GetBusinessLegalQuery>(api, getBusinessLegalQueryGql, {
    input,
  });
};
