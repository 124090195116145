import {
  SEMATTRS_EXCEPTION_TYPE,
  SEMATTRS_HTTP_METHOD,
  SEMATTRS_HTTP_STATUS_CODE,
  SEMATTRS_HTTP_URL,
} from '@opentelemetry/semantic-conventions';

export enum SemanticAttributes {
  EXCEPTION_TYPE = SEMATTRS_EXCEPTION_TYPE,
  HTTP_STATUS_CODE = SEMATTRS_HTTP_STATUS_CODE,
  HTTP_URL = SEMATTRS_HTTP_URL,
  HTTP_METHOD = SEMATTRS_HTTP_METHOD,
}

export const enum MoreSemanticAttributes {
  // https://opentelemetry.io/docs/specs/otel/trace/semantic_conventions/instrumentation/graphql/
  GRAPHQL_OPERATION_NAME = 'graphql.operation.name',
  GRAPHQL_OPERATION_TYPE = 'graphql.operation.type',
  GRAPHQL_DOCUMENT = 'graphql.document',
}

export const enum NonStandardSemanticAttributes {
  GRAPHQL_OPERATION_VARIABLES = 'graphql.operation.variables',

  APOLLO_ERROR_COUNT = 'graphql.apollo.error.length',
  APOLLO_FIRST_ERROR_TYPE = 'graphql.apollo.error.0.type',
  APOLLO_FIRST_ERROR_MESSAGE = 'graphql.apollo.error.0.message',

  AWS_COGNITO_USER_POOL_ID = 'aws.cognito.user_pool_id',
  AWS_COGNITO_USER_POOL_CLIENT_ID = 'aws.cognito.user_pool_client_id',
  AWS_COGNITO_IDENTITY_POOL_CLIENT_ID = 'aws.cognito.identity_pool_id',
}

export const enum CottageSemanticAttributes {
  BUSINESS_ID = 'cottage.business_id',
  LOCATION_ID = 'cottage.location_id',
  CONSUMER_ID = 'cottage.consumer_id',
}
